<template>
  <div class="searchBox1">
    <div>
      <div class="inputBox1 borderno">
        <el-input v-model="value" placeholder="搜地点"  @focus="seacher"/>
        <span @click="SearchClick()">
          <i class="el-icon-search"></i>
        </span>
      </div>
      <div
        v-if="SearchResultArray && SearchResultArray.length > 0"
        class="SearchResultDataClass"
      >
        <div
          v-for="(item, index) in SearchResultArray"
          :key="index"
          class="ResultItem"
          @click="SearchItemClick(item)"
        >
          <i class="el-icon-location-outline"></i>
          <div class="SearchFont" :title="item.district + item.name">
            {{ item.district }}{{ item.name }}
          </div>
        </div>
        <div class="hide" @click="SearchCancel">收起</div>
      </div>
    </div>
    <!-- <div class="branch borderno">
      <div class="dq">
        <el-select v-model="regionValue" placeholder="请选择行政区" clearable @change="regoinChange">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="`${item.label}|${item.value}`"
          >
          </el-option>
        </el-select>
      </div>
    </div> -->
    <div class="simulation">
      <el-popover
        popper-class="layerpopo"
        trigger="manual"
        placement="right-start"
      >
        <li slot="reference" @click="ClickLeftMenu(3)">
          <i class="el-icon-copy-document"></i>图层
        </li>
      </el-popover>
      <el-popover
        popper-class="layerpopo"
        trigger="manual"
        visible-arrow="false"
        v-model="visible"
      >
        <li slot="reference" @click="ClickLeftMenu(1)">
          <i class="el-icon-search"></i>事件
        </li>
      </el-popover>
      <el-popover
        popper-class="layerpopo"
        trigger="manual"
        visible-arrow="false"
        v-model="visible"
      >
        <li slot="reference" @click="ClickLeftMenu(2)">
          <i class="el-icon-search"></i>人员
        </li>
      </el-popover>
      <div
        class="LeftFunction"
        
        v-if="SelectMenuData == 3"
      >
        <div class="popoverCon">
          <!-- <el-checkbox-group
            v-if="modeal"
               v-model="checkedLayers" @change="selectLayer">
             <el-checkbox v-for="layer in baseLayers" :label="layer.value" :key="layer.value">{{layer.name}}</el-checkbox>
           </el-checkbox-group> -->
           <div style="height:39px">
            <div style="margin: 0px 10px; font-size: 16px;position:absolute;top: 10px;left: 0px;">图层管理</div>
            <i
                  class="el-icon-circle-close"
                  style="margin: 0px 10px; font-size: 16px;position:absolute;top: 10px;right: 0px;cursor:pointer"
                  @click="ClickLeftMenu(3)"
                ></i>
            <div v-show="addLayerShow" class="add-layer">
              <div class="add-item" @click="onCoverage(1)">新建图层分组</div>
              <div class="add-item" @click="onCoverage(2)">新建图层</div>
            </div>
            </div>
          <el-tree
            :data="data"
            show-checkbox
            node-key="id"
            ref="tree"
            style="    height: 454px;
    overflow: auto;"
            v-if="reload"

            :default-expanded-keys="openKeys"
            :default-checked-keys="checkLayer"
            :default-expand-all="isExpand"
            :props="defaultProps"
            @check="handleCheckChange"
            @node-contextmenu="handelNodeContextmenu"
          >
          </el-tree>
        </div>
      </div>
      <!-- 事件管理 -->
      <div class="LeftFunction" v-if="SelectMenuData == 1">
        <div style="height:39px">
            <div style="margin: 0px 10px; font-size: 16px;position:absolute;top: 10px;left: 0px;">事件管理</div>
            <i
                  class="el-icon-circle-close"
                  style="margin: 0px 10px; font-size: 16px;position:absolute;top: 10px;right: 0px;cursor:pointer"
                  @click="ClickLeftMenu(1)"
                ></i>
            </div>
        <el-input
          clearable
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="EventAjaxData.input"
          size="mini"
          style="width: 76%; margin-right: 10px"
        >
        </el-input>
        <el-button @click="SearchDataFun" size="mini">查询</el-button>

        <el-date-picker
          :append-to-body="appendToBodyBool"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="datePicker"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          style="width: 99%; margin-top: 10px; margin-bottom: 10px"
          size="mini"
          @change="DateChange"
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        事件类型:
        <el-cascader
          :append-to-body="appendToBodyBool"
          size="mini"
          style="width: 216px; margin-left: 20px"
          v-model="EventTypevalue"
          :options="EventTypeList"
          @change="EventTypeChange"
          :props="{ children: 'children', label: 'name', value: 'id' }"
          clearable
        ></el-cascader>
        <div style="height: 300px; overflow: auto" class="EventItemBody">
          <div
            v-for="item in EventList"
            @click="EventClickFun(item)"
            :key="item.id"
            class="EventItemClass"
          >
            <div style="font-size: 14px">
              事件类型：{{ item.eventCodeName }}
            </div>
            <div style="font-size: 14px">
              事件状态：{{ item.eventResultsName }}
            </div>
            <div style="font-size: 14px; margin-top: 5px">
              {{ item.eventAddress }}
            </div>
            <div class="EventItemBottom">
              <label style="color: #999; font-size: 12px">
                <i class="iconfont icon-renyuan"></i>
                {{ item.createBy }}
              </label>
              <label style="color: #999; font-size: 12px">
                <i class="iconfont icon-rili"></i>
                {{ item.createTime }}
              </label>
            </div>
          </div>
        </div>
        <div style="width: 100%; text-align: center">
          <el-pagination
            style="margin-bottom: 0px"
            @current-change="EventPageCurrentChange"
            layout="prev, pager, next"
            :pager-count="5"
            :total="EventTotal"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 人员 -->
      <div class="LeftFunction" v-if="SelectMenuData == 2">
        <div style="height:39px">
            <div style="margin: 0px 10px; font-size: 16px;position:absolute;top: 10px;left: 0px;">人员管理</div>
            <i
                  class="el-icon-circle-close"
                  style="margin: 0px 10px; font-size: 16px;position:absolute;top: 10px;right: 0px;cursor:pointer"
                  @click="ClickLeftMenu(2)"
                ></i>
            </div>
        <el-input
          clearable
          placeholder="请输入人员姓名"
          prefix-icon="el-icon-search"
          v-model="PersonAjaxData.name"
          size="mini"
          style="width: 70%; margin-right: 10px"
        >
        </el-input>
        <el-button @click="SearchPersonFun" size="mini">查询</el-button>
        <div style="height: 300px; overflow: auto" class="EventItemBody">
          <div
            v-for="item in PersonList"
            @click="PersonClickFun(item)"
            :key="item.id"
            class="EventItemClass"
          >
            <div style="font-size: 14px">姓名：{{ item.username }}</div>
            <div style="font-size: 14px">手机号：{{ item.mobile }}</div>
            <div style="font-size: 14px">
              状态：{{ item.online == 0 ? "在线" : "离线" }}
            </div>
            <div style="font-size: 14px; margin-top: 5px">
              {{ item.eventAddress }}
            </div>
            <div class="EventItemBottom"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右键图层管理弹出窗口 -->
    <el-dialog
      title="右键图层管理弹出窗口"
      :visible.sync="contextmenuShow"
      :modal="false"
      :show-close="false"
      custom-class="dialog-no-title"
      @contextmenu.prevent.native="contextMenuClose()"
    >
      <div
        class="node-context"
        :style="{ position: 'fixed', left: optionCardX + 'px', top: optionCardY + 'px' }"
      >
        <div
          v-for="(item, index) in nodeListShow"
          :key="index"
          class="node-context-item"
          @click="onOperation(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </el-dialog>
    <!-- 新建图层/图层组 -->
    <el-dialog
      width="356px"
      :title="addCoverageName"
      :visible.sync="addCoverageShow"
      :close-on-click-modal="false"
      class="dialog-class"
      :modal='false'
      @close="onClearn()"
    >
      <div v-if="addCoverageShow">
        <el-form
          ref="layerForm"
          :model="layerForm"
          :rules="rules"
          label-position="top"
          class="demo-ruleForm"
        >
          <el-form-item
            :label="layerForm.layerType == 1 ? '图层分组名称' : '图层名称：'"
            prop="layerName"
          >
            <el-input v-model="layerForm.layerName"></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: center">
              <el-button
                v-if="updataStatus == 0"
                type="primary"
                style="margin-right: 10px"
                @click="onSubmit"
                >保存
              </el-button>
              <el-button v-else type="primary" style="margin-right: 10px" @click="updatasubmitfrom"
                >保存</el-button
              >
              <el-button @click="addCoverageShow = false">取消</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Try, Confirm } from '@/decorator'
import { regionList } from "@/apis/geo";
import Coverlayer from '../../Gis/apis/coverlayer'
import { eventList, personList } from "@/RequestPort/maintenance/task";
import { dicByType ,patrolGetDict} from "@/RequestPort/maintenance";
import {findTreeMap,coverageSort ,coverageAdd,coverageupdate} from "@/RequestPort/Inspection/inspePlan";
import { getUserType, setCompanyId, getCompanyId, setCompanyName } from '../../Gis/utils/auth'
export default {
  name: "Tool",
  components: {},
  props: {
    modeal: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      appendToBodyBool: false,
      options: [],
      regionValue: "",
      value: "",
      SearchResultArray: [],
      marker: null,
      hasMarker: false,
      postionVal: 4.62,
      geojson: null,
      weather: "",
      layers: [],
      layerName: "",
      lastArr: [],
      autoComplete: null,
      // checkedLayers:["'patrol_events'","'patrol_equipments'","'patrol_lines'","'patrol_networks'","'user'"],
      checkedLayers: [],
      baseLayers: [
        {parentId:0, id: "'event'", name: "事件" },
        {parentId:0, id: "'user'", name: "人员" },
        {parentId:0, id: "'networks'", name: "巡检区域" },
        {parentId:0, id: "'clock'", name: "打卡点" }
      ],

      // 树
      data: [],
      // 展开不展开
      isExpand:true,
      // 对应的值
      defaultProps: {
        children: "children",
        label: "name",
        value:"id"
      },
      openKeys:[],
      // 选择值
      checkLayer: [],
      visible: false,
      SelectMenuData: null,
      SearchEvent: {},
      EventArray: [],
      datePicker: "",
      EventAjaxData: {
        address: "",
        endTime: "",
        input: "",
        startTime: "",
        current: 1,
        size: 100,
        eventType: "",
      },
      PersonAjaxData: {
        name: "",
        onlineState: "",
        trackState: "",
        current: 1,
        size: 100000,
      },
      PersonList: [],
      EventList: [],
      EventTypeList: [],
      EventTypevalue: "",
      EventTotal: 0,
      treeChildrenMap: new Map(),
      addLayerShow: false,
      contextmenuShow:false,
      optionCardX: 0,
      optionCardY: 0,
      nodeListShow: [
        { name: '新建图层分组', value: 8, type: 'parent' },
        { name: '新建图层', value: 7, type: 'parent' },
        { name: '关闭其他图层', value: 1, type: 'children' },
        { name: '上移', value: 2, type: 'public' },
        { name: '下移', value: 3, type: 'public' },
        { name: '重命名', value: 4, type: 'public' },
      ],
      addCoverageShow:false,
      addCoverageName:'',
      layerForm:{},
      rules: {
        layerName: [
          { required: true, message: '请输入名称', trigger: 'change' },
          { max: 50, message: '超出最大限制', trigger: ['change', 'blur'] }
        ]
      },
      selective:[],
      reload:true,
      notdata:[],
      allNode:[]
    };
  },
  computed: {},
  watch: {
    SelectMenuData() {
      this.$parent.map.remove(this.$parent.markerHighLight);
      if (this.$parent.userpolyline) {
        this.$parent.map.remove(this.$parent.userpolyline);
      }
      if (this.$parent.labelsLayerUser) {
        this.$parent.map.remove(this.$parent.labelsLayerUser);
      }
    },
  },
  
   destroyed () {
    this.$eventBus.$off('coverageOpen') 
    this.$eventBus.$off('coverageNumber')
    this.$eventBus.$off('treeCheckAll') 
    this.$eventBus.$off('coverageOpenTWO') 
    this.$eventBus.$off('coverageclose') 
  },
  mounted() {
    
    // 恢复全选
    this.$eventBus.$on('treeCheckAll', data => { 
      let choice = []
      this.data.forEach(el => {
        choice.push(el.id)
      })
      this.checkLayer = choice
    })
    // 打开图层
    this.$eventBus.$on('coverageOpen', data => { 
      // this.ClickLeftMenu(3)
      this.SelectMenuData = 3
      this.checkLayer = []
      this.handleCheckChange()
    })
    // 编辑的时候打开图层
    this.$eventBus.$on('coverageOpenTWO', data => { 
      // this.ClickLeftMenu(3)
      this.SelectMenuData = 3
      this.handleCheckChange()
      // this.checkLayer = [this.selective]
      // this.$refs.tree.setCheckedNodes([this.selective])
      
    })
    // 取消编辑片区关闭图层
    this.$eventBus.$on('coverageclose', data => { 
      // this.ClickLeftMenu(3)
      this.SelectMenuData =null
      this.$store.commit('setLayerTree', '')
      this.$eventBus.$emit("wmlineWalking", {layer:'linqing:lineWalking'});
    })
    // 片区编辑时把图层回显
    this.$eventBus.$on('coverageNumber', data => { 
       this.checkLayer =typeof(data) == 'number' ? [data] : data.split(',')
       this.reload = true
     })
    //  this.getRegion()
    this.getFindTree()
    const nowInWhere = window.location.hash;
    if (nowInWhere.indexOf("myEquipment") != -1) {
      this.checkedLayers = ["'patrol_equipments'", 
      // "'patrol_networks'"
      ];
    } else if (nowInWhere.indexOf("myLine") != -1) {
      this.checkedLayers = ["'patrol_lines'", 
      // "'patrol_networks'"
      ];
    } else if (nowInWhere.indexOf("network") != -1) {
      this.checkedLayers = [
        "'patrol_events'",
        "'patrol_equipments'",
        "'patrol_lines'",
        // "'patrol_networks'",
        "'user'",
      ];
    }
    if (this.$store.getters.pulginLoad) {
    //   that.initMap()
    // }
    
    }
    this.EventListFun();
    this.PersonListFun();
    dicByType({ parentCode: "GAS_PATROL_EVENT", level: 2 }).then((res) => {
      if (res.code === 200) {
        this.EventTypeList = res.data;
      }
    });
  },
  methods: {
    seacher(){
      let that = this;
      AMap.plugin(["AMap.AutoComplete"], function () {
        let autoOptions = {};
        that.autoComplete = new AMap.AutoComplete(autoOptions);
        // that.selectLayer(that.checkedLayers);
      });
    },
    /*
     ****************************************************************************************************
     *    功能描述： 关闭右键图层弹出窗口
     *    开发人： 宋德福
     *    开时间： 2022-5-13 13:35
     *    重点参数含义：@contextmenuShow = false 不显示
     ****************************************************************************************************
     */
    contextMenuClose() {
      this.contextmenuShow = false
    },
     /*
     ****************************************************************************************************
     *    功能描述： 当某一节点被鼠标右键点击时会触发该事件
     *    开发人： 宋德福
     *    开时间： 2022-5-16 15:23
     *    重点参数含义：
     *    @ev event
     *    @data 节点所对应的对象
     *    @n 节点对应的 Node
     *    @t 节点组件本身
     ****************************************************************************************************
     */
    handelNodeContextmenu(ev, data, n, t) {
      this.treeCurrent = n.data
      if(data.children){
        this.selective = this.childrens(data.children)
      } else {
        this.selective = data.id
      }
      // 新增使用的id
      this.layerForm.id = n.parent.data.id
      this.treeParentCurrent = data.parentId == 0 ? n.parent.data.children : n.parent.data.children
      let { nodeList } = this
      // 是gis数据不可操作
      if (data.sysType == 1) {return }
      if(data.layerType == 1) {
        this.nodeListShow = [
          { name: '新建图层分组', value: 8, type: 'parent' },
          { name: '新建图层', value: 7, type: 'parent' },
          { name: '重命名', value: 4, type: 'public' },
          { name: '上移', value: 2, type: 'public' },
          { name: '下移', value: 3, type: 'public' },
          { name: '删除图层组', value: 5, type: 'children' },
        ]
      } else {
        this.nodeListShow = [
          { name: '重命名', value: 4, type: 'public' },
          { name: '上移', value: 2, type: 'public' },
          { name: '下移', value: 3, type: 'public' },
          { name: '关闭其他图层', value: 1, type: 'children' },
          { name: '删除图层', value: 5, type: 'children' },
        ]
      }
      this.optionCardX = ev.x // 让右键菜单出现在鼠标右键的位置
      this.optionCardY = ev.y
      this.contextmenuShow = true // 展示右键菜单
    },
    childrens(children){
      let that = this
      let checkLayer = []
      children.forEach(el => {
        if(el.children) {
          that.childrens(el.children)
        } else {
          checkLayer.push(el.id)
        }
      })
      return checkLayer
    },
    /**
     * 选择右键事件
     */
    onOperation(item) {
      this.checkLayer = [49]
      let { treeCurrent } = this
      switch (item.value) {
        case 1:
          // 关闭其他图层
          this.checkLayer = [this.selective]
          this.$refs.tree.setCheckedNodes([this.selective])
          this.handleCheckChange()
          break
        case 7:
          this.$set(this.layerForm, 'layerType', 2)
          this.$set(this.layerForm, 'pid', this.treeCurrent.id)
          this.addCoverageName = '新建图层'
          this.updataStatus = 0
          this.addCoverageShow = true
          break
        case 8:
          this.$set(this.layerForm, 'layerType', 1)
          this.$set(this.layerForm, 'pid', this.treeCurrent.id)
          this.addCoverageName = '新建图层分组'
          this.updataStatus = 0
          this.addCoverageShow = true
          break
        case 4:
          this.layerForm = {
            id: treeCurrent.id,
            layerName: treeCurrent.name,
            layerType: treeCurrent.parentId == 0 ? 1 : 2,
            pid: treeCurrent.parentId,
            sysType:2
          }
          this.updataStatus = 1
          this.addCoverageName = '重命名'
          this.addCoverageShow = true
          break
        case 2:
          this.upData()
          break
        case 3:
          this.downData()
          break
        case 5:
          this.getDelete(this.treeCurrent.id, this.treeCurrent)
          break
        case 6:
          // this.getDelete(this.treeCurrent.id, this.treeCurrent)
          this.ids = []
          this.exportExcel()
          break
      }
      this.treeCurrent = null
      this.treeParentCurrent = null
      this.contextmenuShow = false
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    },
    /***
     * 新建图层
     */
    async onSubmit() {
      let { layerForm } = this
      layerForm.sysType = 2
      // 创建最上层id
      if(!this.layerForm.id) {
        this.layerForm.pid = 0
      }
      this.$refs['layerForm'].validate(async valid => {
        if (valid) {
          try {
            let res = await coverageAdd(layerForm)
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.addCoverageShow = false
            let type = layerForm.layerType == 1 ? false : true
            this.getFindTree(type)
          } 
          catch (error) {
            // this.$message.error(error.message)
          }
        }
      })
    },
    // 删除图层
    async getDelete(id, item, confirm) {
      let mag = item.parentId == 0 ? '确认删除该图层组？' : '确认删除该图层？'
      await Confirm(mag, true)
      let res = await Coverlayer.getDelete({ id, companyId: getCompanyId(),sysType:2 })
      if (id == this.activeLayer) this.activeLayer = null
      let type = item.parentId == 0 ? false : true
      this.getFindTree(type)
    },
    /**
     * 重命名
     */
    async updatasubmitfrom() {
      let { layerForm } = this
      // layerForm.companyId = this.enterValue.value
      this.$refs['layerForm'].validate(async valid => {
        if (valid) {
          try {
            let res = await coverageupdate(layerForm)
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.addCoverageShow = false
            this.getFindTree()
          } catch (error) {
            // this.$message.error(error.message)
          }
        }
      })
    },
    //上移
    upData() {
      let index = this.treeParentCurrent.findIndex(el => el.id === this.treeCurrent.id)
      if (index != -1 && index != 0) {
        this.treeParentCurrent = this.swapItems(this.treeParentCurrent, index, index - 1)
        this.setSort(this.treeCurrent, this.treeParentCurrent, this.treeCurrent.parentId)
      }
    },

    //下移
    downData() {
      let index = this.treeParentCurrent.findIndex(el => el.id === this.treeCurrent.id)
      if (index != -1 && index != this.treeParentCurrent.length - 1) {
        this.treeParentCurrent = this.swapItems(this.treeParentCurrent, index, index + 1)
        this.setSort(this.treeCurrent, this.treeParentCurrent, this.treeCurrent.parentId)
      }
    },
    /**
     * 添加完清空
     */
    onClearn() {
      this.layerForm = {}
    },
    /*
     ****************************************************************************************************
     *    功能描述： 排序
     *    开发人： 庞红川
     *    开时间： 2022-5-23 15:05
     *    重点参数含义：
     *    @draggingNode 当前节点
     *    @dropNode 被放置节点
     *    @pid 被放置节点的id
     ****************************************************************************************************
     */
    async setSort(draggingNode, dropNode, pid) {
      let obj = {
        id: draggingNode.id,
        pid: pid,
        sorts: []
      }
      dropNode.forEach((el, index) => {
        let list = {
          id: el.id,
          sort: index + 1
        }
        obj.sorts.push(list)
      })
      try {
        let res = await coverageSort(obj)
        this.$message({
          message: res.msg,
          type: 'success'
        })
        await this.getFindTree()
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    onCoverage(type) {
      this.$set(this.layerForm, 'layerType', type)
      if (type == 1) this.$set(this.layerForm, 'pid', 0)
      this.addCoverageName = type == 1 ? '新建图层分组' : '新建图层'
      this.updataStatus = 0
      this.addCoverageShow = true
      this.addLayerShow = false
    },
    /**
     * 图层
     */
    async getFindTree() {
        this.treeChildrenData = []
        // let p = { companyId: this.enterValue.value }
        let res = await findTreeMap({sysType:1})
        let restwo = await findTreeMap({sysType:2})
        if(!restwo.data){restwo.data = []}
        this.data=[{
          id: 199997,
          children:[...res.data],
          isDelete: 0,
          layerType: 1,
          sysType:1,
          name: "gis图层",
          parentId: 199997,
          weight: 1},
          {
          id: 199998,
          children:[...restwo.data],
          isDelete: 1,
          layerType: 1,
          name: "巡线图层",
          parentId: 199998,
          weight: 1}]
          this.data = [...this.data,...this.baseLayers]
       this.getTreeSee(this.data)
       this.getTreeSee1(this.data)
        this.$store.commit('setLayerTree', this.norepeat(this.checkLayer))
        this.$store.commit('setNotLayerTree', [])
      try {
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    getTreeSee1(data){
      for(let i=0;i<data.length;i++){
        if(data[i].layerType!=1){
          this.allNode.push(data[i].id)
        }
        if (data[i].children) {
          this.getTreeSee1(data[i].children)         
        }
      }
    },
     getTreeSee(data) {
      for(let i=0;i<data.length;i++){
        let el=data[i]
        if (!this.activeLayer && el.layerType != 1) {
          this.activeLayer = el.id
         // this.setStroeCurrent(el.id)
        }
        if (this.treeChildrenMap.has(el.id)) {
          this.$set(el, 'isSee', this.treeChildrenMap.get(el.id))
          if (this.treeChildrenMap.get(el.id) == 2 && !el.children) this.checkLayer.push(el.id)
        } else {
          this.$set(el, 'isSee', 2)
          this.treeChildrenMap.set(el.id, 2)
          this.checkLayer.push(el.id)
        }
        this.treeChildrenData.push(el)
        
        if (el.children) {
          this.getTreeSee(el.children)         
        }
      }
    },
    /**
     * 点击树
     */
    checkClick(val){
      let array = []
      this.checkLayer.forEach(el => {
        if(typeof(Number(el)) === 'number' && Number(el) === Number(el)) {
          array.push(Number(el))
        }
      })
      return array
    },
     getcheck(){
      let check=this.$refs.tree.getCheckedNodes()
          if(check&&check.length>0){
            this.checkLayer=[]
            this.getTreeSee(check)
            return this.checkLayer
          }
          return []

    },
      norepeat(arr){
            let a = []
            for(var i in arr){
                if(a.indexOf(arr[i])==-1){
                    a.push(arr[i])
                }
            }
            return a
  },
  /** 
   * 图层选择
   */
    handleCheckChange(val){  
      this.$eventBus.$emit("RemovepopUp");
      this.$nextTick(()=>{
        let arr=this.getcheck()
        
        // if(this.norepeat(arr).length>=this.allNode.length){
        //   this.notdata = []
        // }else{
          // let notdata = JSON.parse(JSON.stringify(this.notdata))
          // this.allNode.forEach((el,key)=>{
          //   if(this.norepeat(arr).indexOf(key)<0){
          //     if(notdata.indexOf(key)>=0){
          //       notdata.splice(notdata.indexOf(key),1)
          //     }else{
          //       notdata.push(key)
          //     }
              
          //   }
          // })
        //}
        // console.log(this.allNode,'ddd')
        this.$store.commit('setLayerTree', this.norepeat(arr))
        // this.$store.commit('setNotLayerTree',this.notdata)
      })
    },
    SearchPersonFun() {
      this.PersonListFun();
    },
    DateChange() {
      this.SearchDataFun();
    },
    PersonClickFun(e) {
      if(e.online == 0 ) {
        if (e.location && e.location.length>0) {
          // 定位到中心位置
          this.$parent.setCenter(e.location);
          this.$parent.SetMarkerHighLight(e.location);
          this.$parent.setUserPath(e);
          this.$eventBus.$emit("personList");
          // 展示轨迹
          this.$eventBus.$emit("wmsLayerers",{sql:'executor_people_id='+e.id});
        } else {
          this.$message.error("未获取到该人员位置信息，请联系该巡线员进行手机配置");
        }
      } else {
        this.$message.error("用户暂无位置信息");
      }
    },
    PersonListFun() {
      personList(this.PersonAjaxData).then((e) => {
        this.PersonList = e.data.records;
      });
    },
    EventClickFun(e) {
      this.$parent.setCenter(e.locations);
      this.$parent.SetMarkerHighLight(e.locations);
    },
    EventPageCurrentChange(e) {
      this.EventAjaxData.current = e;
      this.EventListFun();
    },
    EventListFun() {
      eventList(this.EventAjaxData).then((e) => {
        this.EventList = e.data.records;
        this.EventTotal = e.data.total;
      });
    },
    EventTypeChange(e) {
      this.EventAjaxData.eventType = e[1];
      this.SearchDataFun();
    },
    /**
     * 图层选中事件
     */
    // selectLayer(val) {
    //   let layer = "";
    //   if (val && val.length > 0) {
    //     layer = val.toString();
    //     this.$eventBus.$emit("wmsLayer", layer);
    //   } else {
    //     this.$eventBus.$emit("clearLayer", layer);
    //   }
    // },
    /**
     * 左侧按钮
     */
    ClickLeftMenu(e) {
      let that = this
      if(e == 3) {
        // 图形树
        // patrolGetDict({pid:'524,518,70,46,512,517,527,5,528,532,533'}).then(res => {
        //   that.data = res.data
        //   // 全选
        //   that.data.forEach(res => {
        //     that.checkLayer.push(res.id)
        //   })
        // })
        // this.$eventBus.$emit("Clicktree");
      }
      if (this.SelectMenuData == e) {
        this.SelectMenuData = null;
        this.$emit("ClickMenu", e);
      } else {
        this.SelectMenuData = e;
        this.$emit("ClickMenu", e);
      }
    },
    SearchDataFun() {
      if (this.datePicker) {
        this.EventAjaxData.startTime = this.datePicker[0];
        this.EventAjaxData.endTime = this.datePicker[1];
      } else {
        this.EventAjaxData.startTime = "";
        this.EventAjaxData.endTime = "";
      }
      this.EventPageCurrentChange();
    },
    regoinChange(e) {
      let value = this.regionValue;
      if (value) {
        let [label, value] = this.regionValue.split("|");
        this.getRegionFeature(value);
      } else {
        this.$parent.map.remove(this.geojson);
      }
    },
    SearchCancel() {
      this.SearchResultArray = null;
    },
    SearchClick() {
      if (this.value) {
        this.autoComplete.search(this.value, (status, result) => {
          this.SearchResultArray = result.tips;
        });
      } else {
        this.SearchResultArray = [];
      }
    },
    getRegion() {
      let parames = {
        SERVICE: "wfs",
        VERSION: "1.0.0",
        REQUEST: "GetFeature",
        typeName: "gas:tsdivision",
        outputFormat: "application/json",
        propertyName: "NAME",
      };
      //FEATUREID
      regionList(parames).then((res) => {
        let feature = res.features;
        let option = []; // [{ label: '唐山', value: '' }]
        for (let i = 0; i < feature.length; i++) {
          let item = {
            label: feature[i].properties.NAME,
            value: feature[i].id,
          };
          option.push(item);
        }
        this.options = option;
      });
    },
    getRegionFeature(FEATUREID) {
      let that = this;
      let parames = {
        SERVICE: "wfs",
        VERSION: "1.0.0",
        REQUEST: "GetFeature",
        typeName: "gas:tsdivision",
        outputFormat: "application/json",
        FEATUREID,
      };
      //FEATUREID
      regionList(parames).then((res) => {
        if (this.geojson) {
          this.$parent.map.remove(this.geojson);
        }
        this.geojson = new AMap.GeoJSON({
          geoJSON: res,
          zIndexOffset: 1,
          getPolygon: function (geojson, lnglats) {
            return new AMap.Polygon({
              path: lnglats,
              fillOpacity: 0.2,
              strokeColor: "#45515d",
              fillColor: "#01d8ff",
            });
          },
        });
        this.geojson.on("click", function (e) {
          that.$parent.getFeatrue(e.lnglat);
        });
        this.$parent.map.add(this.geojson);
        this.$parent.map.setFitView(this.geojson._overlays);
      });
    },
    SearchItemClick(e) {
      if (this.hasMarker) {
        this.$parent.map.remove(this.marker);
      }
      this.value = e.district + e.name
      this.hasMarker = true;
      let center = e.location;
      this.$parent.map.setCenter(center);
      this.SearchResultArray = null;
      this.marker = new AMap.Marker({
        position: center,
        content:
          '<div> <div class="scatterPoint scatterPoint2"></div>' +
          '<div class="scatterPoint scatterPoint3" ></div></div>',
        offset: new AMap.Pixel(0, 0),
      });
      this.$parent.map.add(this.marker);
      let that = this;
      setTimeout(() => {
        this.$parent.map.remove(this.marker);
        this.hasMarker = false;
      }, 60000);
    },
  },
};
</script>
<style lang="scss">
.popoList {
  span {
    display: block;
    padding: 0.1rem 0.13rem;
    cursor: pointer;
    color: var(--font-color-skin);
    i {
      color: var(--active-color-skin);
      padding-left: 3px;
      font-weight: bold;
    }
  }
  .spanS:active,
  .spanS:hover {
    background: var(--hover-color-skin);
  }
  .spanS {
    color: var(--active-color-skin);
  }
}
</style>
<style lang="less" scoped>
.EventItemBody {
  margin-top: 20px;
}
.EventItemClass {
  cursor: pointer;
  padding: 5px 0;
}
.EventItemClass:hover {
  background-color: #eee;
}
.EventItemBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-top: 3px;
  border-bottom: 1px solid #eee;
}
.AddressDiv {
  padding: 10px;
}
.AddressDiv:hover {
  background: #f5f2f0;
}
// 图层弹框样式
.LeftFunction {
  padding: 20px;
  position: absolute;
  content: "";
  z-index: 999;
  left: 90px;
  top: 30px;
  background: #fff;
  width: 300px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  max-height: 500px;
  // overflow:auto;
}
.fatherLeftBox {
  position: absolute;
  content: "";
  z-index: 999;
  left: 10px;
  top: 30px;
  height: auto;
  display: flex;
  flex-direction: column;
}
.fatherLeftBox span {
  background: #fff;
  color: #666;
  text-align: center;
  margin-bottom: 5px;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
}
.fatherLeftBox span:hover {
  color: white;
  background: #409eff;
}
.activedMenu {
  color: white !important;
  background: #409eff !important;
}
.EventItemBody::-webkit-scrollbar-thumb {
  border-radius: 0;
  border-style: dashed;
  background-color: #ccc;
  border-color: transparent;
  border-width: 1.5px;
  background-clip: padding-box;
}
.EventItemBody::-webkit-scrollbar {
  width: 9px !important;
  height: 9px !important;
}
.LeftFunction::-webkit-scrollbar-thumb {
  border-radius: 0;
  border-style: dashed;
  background-color: #ccc;
  border-color: transparent;
  border-width: 1.5px;
  background-clip: padding-box;
}
.LeftFunction::-webkit-scrollbar {
  width: 9px !important;
  height: 9px !important;
}
.popoverCon {
  padding: 10px;
  .el-checkbox {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .blockbt {
    display: block;
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 0.7rem;
    line-height: 0.34rem;
    background: rgba(228, 228, 228, 0.1);
    border-radius: 0.05rem;
    cursor: pointer;
    color: var(--active-color-skin);
    box-shadow: 0px 0px 1px var(--shadow-color-skin) inset;
    text-align: center;
  }
  .blockbt.active {
    background: var(--background-c-color-skin);
  }
}
::v-deep {
  .borderno .el-input__inner {
    background: none;
    border: none;
    margin-left: 8px;
    font-size: 0.16rem;
    color: var(--font-b-color-skin);
    height: 32px;
    line-height: 32px;
  }
  .borderno .el-input__icon {
    line-height: 32px;
  }
  .borderno .el-input__suffix {
    height: 32px;
    line-height: 32px;
    right: auto;
    left: 0px;
  }
}
.searchBox1 {
      // height: inherit;
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  transition: left 0.5s;
  .inputBox1 {
    // width: 2.67rem;
    border-radius: 0.22rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background-color-skin);
    border: 1px solid var(--border-color-skin);
    span {
      line-height: 24px;
      padding-right: 0.18rem;
      font-size: 0.2rem;
      color: var(--font-b-color-skin);
      cursor: pointer;
    }
  }

  .hide {
    border-top: 1px solid var(--border-color-skin);
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    color: var(--font-b-color-skin);
    margin-top: 0.1rem;
  }
  .SearchResultDataClass {
    position: absolute;
    width: 288px;
    background: var(--background-a-color-skin);
    border: 1px solid var(--border-color-skin);
    color: var(--font-color-skin);
    margin-top: 0.1rem;
    padding-top: 0.1rem;
    z-index: 10;
  }
  .SearchIconClass {
    height: 100%;
    width: 49px;
    background-size: 100% 100%;
    float: right;
    cursor: pointer;
  }
  .ResultItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    i {
      margin-left: 0.2rem;
      margin-right: 0.1rem;
    }
  }
}
.branch {
  font-size: 0.16rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0.2rem;
  min-width: 2.5rem;
  border-radius: 0.22rem;
  background: var(--background-color-skin);
  color: var(--font-b-color-skin);
  border: 1px solid var(--border-color-skin);
  .weather {
    height: 0.2rem;
    line-height: 0.2rem;
    width: 50%;
    text-align: center;
    border-left: 1px solid var(--border-color-skin);
  }
}
.dq {
  padding-left: 0.18rem;
  width: 1.5rem;
  cursor: pointer;
}
.simulation {
  position: absolute;
  z-index: 1;
  top: 0.5rem;
  left: 0;
  li {
    width: 0.7rem;
    line-height: 0.4rem;
    background: var(--popover-color-skin);
    border-radius: 0.05rem;
    cursor: pointer;
    color: var(--active-color-skin);
    box-shadow: 0px 0px 2px var(--shadow-color-skin) inset;
    margin-top: 0.1rem;
    text-align: center;
    i {
      padding-right: 5px;
    }
  }
}
.EventItemClass {
  cursor: pointer;
  padding: 5px 0;
}
.EventItemClass:hover {
  background-color: #eee;
}
.EventItemBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-top: 3px;
  border-bottom: 1px solid #eee;
}
/deep/.dialog-no-title {
  margin: 0;
  .el-dialog__header {
    display: none;
  }
  .dj-dialog-content {
    padding: 0;
    overflow: unset;
  }
  .el-dialog__body {
    padding: 0;border:none;
  }
  
}
.el-dialog__wrapper{
    background: none!important;
  }
.node-context {
  width: 110px;
  // height: 180px;
  border: 2px solid #e9eef1;
  background: #eaedf0;
  opacity: 0.8;
  position: fixed;
  z-index: 9;

  .node-context-item {
    color: var(--font-color-f1);
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }

  .node-context-item:hover {
    background: #feffff;
  }
}
</style>